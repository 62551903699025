import React, { useState } from 'react';
import {
    useBreakpointValue,
    Box,
    Flex,
    HStack,
    IconButton,
    Image,
    Drawer,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    DrawerBody,
    Stack,
    useDisclosure,
    Button,
} from '@chakra-ui/react';
import { HamburgerIcon } from '@chakra-ui/icons';
import { useNavigate } from 'react-router-dom';
import { colors } from '../styles/colors';
import ModalRecharge from './modalRecharge';

const Header = ({ dark = false, token = null, ...rest }) => {
    const navigate = useNavigate();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [isRechargeModalOpen, setRechargeModalOpen] = useState(false);
    const isMobile = useBreakpointValue({ base: true, md: false });

    const menuItems = [
        { label: 'Home', onClick: () => navigate('/') },
        { label: 'Sobre nós', onClick: () => navigate('/about') },
        { label: 'Planos', onClick: () => (window.location.href = 'https://compreaqui.flw.net.br/') },
        { label: 'Nova Recarga', onClick: () => setRechargeModalOpen(true) },
        { label: 'Faq', onClick: () => navigate('/faq') },
    ];

    return (
        <Box as="header" {...rest}>
            <Flex
                bg={colors.white} // Fundo branco para o tema claro
                color={colors.black} // Texto preto para contraste
                justifyContent="space-between"
                alignItems="center"
                px={{ base: 4, md: 8 }} // Margens laterais
                py={4}
                borderBottomWidth={1} // Espessura da linha inferior
                borderColor={colors.gray[200]} // Cor da linha inferior
            >
                {/* Logo da Empresa */}
                <Image
                    cursor="pointer"
                    onClick={() => navigate('/')}
                    src={require('../assets/icons/logo-black.png')} // Certifique-se de que a logo é adequada para fundo branco
                    w={100}
                />

                {/* Navegação Desktop */}
                {!isMobile && (
                    <HStack spacing={4}>
                        {menuItems.map((item) => (
                            <Button
                                key={item.label}
                                variant="ghost"
                                color={colors.black} // Texto preto
                                _hover={{ color: colors.primary }} // Hover com cor primária
                                onClick={item.onClick}
                            >
                                {item.label}
                            </Button>
                        ))}
                    </HStack>
                )}

                {/* Navegação Mobile */}
                {isMobile && (
                    <IconButton
                        icon={<HamburgerIcon />}
                        variant="outline"
                        colorScheme="blackAlpha"
                        color={colors.black}
                        onClick={onOpen}
                        aria-label="Abrir Menu"
                    />
                )}
            </Flex>

            {/* Menu Lateral Mobile */}
            <Drawer placement="left" onClose={onClose} isOpen={isOpen}>
                <DrawerOverlay />
                <DrawerContent bg={colors.white}>
                    <DrawerCloseButton color={colors.black} />

                    <DrawerBody>
                        <Stack spacing={4} mt={10}>
                            {menuItems.map((item) => (
                                <Button
                                    key={item.label}
                                    variant="ghost"
                                    color={colors.black} // Texto preto
                                    _hover={{ color: colors.primary }} // Hover com cor primária
                                    onClick={() => {
                                        onClose();
                                        item.onClick();
                                    }}
                                >
                                    {item.label}
                                </Button>
                            ))}
                        </Stack>
                    </DrawerBody>
                </DrawerContent>
            </Drawer>

            {/* Modal de Recarga */}
            {isRechargeModalOpen && (
                <ModalRecharge
                    isOpen={isRechargeModalOpen}
                    onClose={() => setRechargeModalOpen(false)}
                />
            )}
        </Box>
    );
};

export default Header;
